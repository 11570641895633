import React from 'react';
import TextBlockComponent from './components/text-block/TextBlock.component';
import TeamAvionautComponent from './components/team-avionaut/TeamAvionaut.component';
import NumberComponent from './components/number/Number.component';

import style from './AboutUs.module.scss';
import ValueComponent from './components/value/Value.component';

import Setting from '../../assets/svg/setting.svg';
import Shield from '../../assets/svg/shield.svg';
import Line from '../../assets/svg/line.svg';
import Star from '../../assets/svg/star22.svg';
import Archive from '../../assets/svg/archive.svg';
import Layer from '../../assets/svg/layer.svg';
import SmallWhiteLogo from '../../assets/svg/avionaut.svg';
import BannerOne from '../../assets/images/banner-one.jpg';
import BannerTwo from '../../assets/images/banner-two.jpg';
import BackgroundImageComponent from './components/background-image/BackgroundImage.component';
import HistoryElementComponent from './components/history-element/HistoryElement.component';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';
import CountryMapComponent from './components/country-map/CountryMap.component';
import { useWindowSize } from '../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

const AboutUs = () => {
  const intl = useIntl();

  return (
    <div>
      <TextBlockComponent header={intl.formatMessage({ id: 'aboutUs__header'})}
                          isH1
                          text={intl.formatMessage({ id: 'aboutUs__headerOne'})}/>
      <TeamAvionautComponent/>
      <div className={style.aboutUs__numbers}>
        <NumberComponent num={intl.formatMessage({ id: 'aboutUs__numberOne'})} text={intl.formatMessage({ id: 'aboutUs__numberOneText' })}/>
        <div className={style.aboutUs__divider}/>
        <NumberComponent num={intl.formatMessage({ id: 'aboutUs__numberFour'})} text={intl.formatMessage({ id: 'aboutUs__numberFourText' })}/>
        <div className={style.aboutUs__divider}/>
        <NumberComponent num={intl.formatMessage({ id: 'aboutUs__numberThree'})} text={intl.formatMessage({ id: 'aboutUs__numberThreeText' })}/>
        <div className={style.aboutUs__divider}/>
        <NumberComponent num={intl.formatMessage({ id: 'aboutUs__numberTwo'})} text={intl.formatMessage({ id: 'aboutUs__numberTwoText' })}/>

      </div>
      <TextBlockComponent header={intl.formatMessage({ id: 'aboutUs__mission'})}
                          text={intl.formatMessage({ id: 'aboutUs__missionValue'})}/>
      <div className={style.aboutUs__values}>
        <ValueComponent graphic={<Setting/>} graphicAlt={'value graphic'}
                        key={1}
                        title={intl.formatMessage({ id: 'aboutUs__value1'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value1Text'})}/>
        <ValueComponent graphic={<Line/>} graphicAlt={'value graphic'}
                        key={3}
                        title={intl.formatMessage({ id: 'aboutUs__value3'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value3Text'})}/>
        <ValueComponent graphic={<Star/>} graphicAlt={'value graphic'}
                        key={4}
                        title={intl.formatMessage({ id: 'aboutUs__value4'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value4Text'})}/>
        <ValueComponent graphic={<Archive/>} graphicAlt={'value graphic'}
                        key={5}
                        title={intl.formatMessage({ id: 'aboutUs__value5'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value5Text'})}/>
        <ValueComponent graphic={<Layer/>} graphicAlt={'value graphic'}
                        key={6}
                        title={intl.formatMessage({ id: 'aboutUs__value6'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value6Text'})}/>
        <ValueComponent graphic={<Shield/>} graphicAlt={'value graphic'}
                        key={2}
                        title={intl.formatMessage({ id: 'aboutUs__value2'})}
                        subtitle={intl.formatMessage({ id: 'aboutUs__value2Text'})}/>
      </div>
      <div className={style.aboutUs__banner}>
        <BackgroundImageComponent imagePath={BannerOne}>
          <div className={style.aboutUs__bannerContent}>
            <SmallWhiteLogo/>
            <h2
              className={style.aboutUs__bannerText}>{intl.formatMessage({ id: 'aboutUs__bannerOne'})}
            </h2>
            <div className={style.aboutUs__bannerIcons}>
              <StaticImage
                src={'../../assets/images/adac-cert.jpg'}
                alt={'Adac Cert'}
                objectFit={'cover'}
                className={style.aboutUs__bannerImage}
                quality={100}
              />
              <StaticImage
                src={'../../assets/images/plus-cert.jpg'}
                alt={'Plus Cert'}
                objectFit={'cover'}
                className={style.aboutUs__bannerImage}
                quality={100}
              />
              <StaticImage
                src={'../../assets/images/agr-cert.jpg'}
                alt={'Agr Cert'}
                objectFit={'cover'}
                className={style.aboutUs__bannerImage}
                quality={100}
              />
            </div>
          </div>
        </BackgroundImageComponent>
      </div>
      <TextBlockComponent header={intl.formatMessage({ id: 'aboutUs__improvement'})}
                          text={intl.formatMessage({ id: 'aboutUs__improvementValue'})}/>
     <div className={style.aboutUs__history}>
       <HistoryElementComponent key={1} time={intl.formatMessage({ id: 'aboutUs__history1'})} text={intl.formatMessage({ id: 'aboutUs__history1Text' })} />
       <HistoryElementComponent key={2} time={intl.formatMessage({ id: 'aboutUs__history2'})} text={intl.formatMessage({ id: 'aboutUs__history2Text' })} />
       <HistoryElementComponent key={3} time={intl.formatMessage({ id: 'aboutUs__history3'})} text={intl.formatMessage({ id: 'aboutUs__history3Text' })} />
       <HistoryElementComponent key={4} time={intl.formatMessage({ id: 'aboutUs__history4'})} text={intl.formatMessage({ id: 'aboutUs__history4Text' })} />
     </div>
      <div className={style.aboutUs__banner}>
        <CountryMapComponent />
      </div>
    </div>
  );
};

export default AboutUs;
