import React from 'react';

import style from './HistoryElement.module.scss';

import Time from './../../../../assets/svg/time.svg';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import HistoryElementMobileComponent from './HistoryElementMobile.component';
import HistoryElementDesktopComponent from './HistoryElementDesktop.component';

interface IHistoryElementComponent {
  time: string;
  text: string;
}

const HistoryElementComponent = ({time, text}: IHistoryElementComponent) => {
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.TABLET);
  
  return (
    isMobile ? <HistoryElementMobileComponent time={time} text={text} /> : <HistoryElementDesktopComponent time={time} text={text} />
  )
}

export default HistoryElementComponent;
