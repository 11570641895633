import { StaticImage } from 'gatsby-plugin-image';
import style from './CountryMap.module.scss';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

const CountryMapComponent = () => {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.TABLET);
  
  return (
    <div className={style.countryMapComponent}>
      <div className={style.countryMapComponent__text}>
        <p className={style.countryMapComponent__header}>{intl.formatMessage({ id: 'aboutUs__mapHeader' })}</p>
        <p className={style.countryMapComponent__paragraph}>{intl.formatMessage({ id: 'aboutUs__mapText' })}</p>
        <ul className={style.countryMapComponent__list}>
          <li>{intl.formatMessage({ id: 'ecPoland' })}</li>
          <li>{intl.formatMessage({ id: 'ecGermany' })}</li>
          <li>{intl.formatMessage({ id: 'ecUK' })}</li>
          <li>{intl.formatMessage({ id: 'ecCzech' })}</li>
          <li>{intl.formatMessage({ id: 'ecSlovakia' })}</li>
          <li>{intl.formatMessage({ id: 'ecSpain' })}</li>
          <li>{intl.formatMessage({ id: 'ecPortugal' })}</li>
          <li>{intl.formatMessage({ id: 'ecFrance' })}</li>
          <li>{intl.formatMessage({ id: 'ecItaly' })}</li>
          <li>{intl.formatMessage({ id: 'ecTurkey' })}</li>
          <li>{intl.formatMessage({ id: 'ecCroatia' })}</li>
          <li>{intl.formatMessage({ id: 'ecRomania' })}</li>
          <li>{intl.formatMessage({ id: 'ecAustria' })}</li>
          <li>{intl.formatMessage({ id: 'ecMalta' })}</li>
          <li>{intl.formatMessage({ id: 'ecSwitzerland' })}</li>
        </ul>
      </div>
      <div className={style.countryMapComponent__map}>
        {
          isMobile ?
            <StaticImage
              src={'../../../../assets/images/europe-small.png'}
              alt={'Agr Cert'}
              objectFit={'cover'}
              className={style.countryMapComponent__image}
              quality={100}
            /> :
            <StaticImage
              src={'../../../../assets/images/europe-big.png'}
              alt={'Agr Cert'}
              objectFit={'cover'}
              className={style.countryMapComponent__image}
              quality={100}
            />
        }
  
      </div>
    </div>
  )
}

export default CountryMapComponent;
