import React from 'react';

interface IBackgroundImageComponent {
  imagePath: string;
  children: any;
}

const BackgroundImageComponent = ({ imagePath, children }: IBackgroundImageComponent) => {
  const backgroundStyle = {
    backgroundImage: `url(${imagePath})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    width: '100%', 
    maxWidth: '1440px',
    height: '500px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div style={backgroundStyle}>
      {children} 
    </div>
  );
};

export default BackgroundImageComponent;
