import React from 'react';
import style from './TextBlock.module.scss';

interface ITextBlockComponent {
  header: string;
  text: string;
  isH1?: boolean;
}

const TextBlockComponent = ({header, text, isH1}: ITextBlockComponent) => {
  return (
    <div className={style.textBlock}>
      {
        isH1 ?
          <h1 className={style.textBlock__header}>{header}</h1>:
          <h2 className={style.textBlock__header}>{header}</h2>
      }
      <p className={style.textBlock__text}>{text}</p>
    </div>
  )
}

export default TextBlockComponent;
