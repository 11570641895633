import React from 'react';
import { Script } from 'gatsby';

import AboutTemplate from '../../app/about-us/AboutUs';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useIntl } from 'gatsby-plugin-intl';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import style from './About.module.scss';

const About = () => {
  const intl = useIntl();

  const canonicalLink = 'https://avionaut.com' + `${getLink(intl.locale, PageType.ABOUT)}` + '/';
  
  const jsonData = {
    '@context': 'https://schema.org/',
    '@graph':
      [
        {
          '@type': 'Organization',
          'url': `https://avionaut.com${getLink(intl.locale, PageType.ABOUT)}`,
          'logo': 'https://avionaut.com/avionaut.svg'
        }
      ]
  }

  return (
    <>
      <Script type="application/ld+json">{JSON.stringify(jsonData)}</Script>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        canonical={canonicalLink}
        title={intl.formatMessage({ id: 'aboutUs__title' })}
        description={intl.formatMessage({ id: 'aboutUs__metaDescription' })}
        metaTags={[
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'aboutUs__metaTitle' }),
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'aboutUs__metaDescription' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'aboutUs__metaTitle' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'aboutUs__metaDescription' }),
          },
        ]}
      />
      <div className={style.about}>
        <AboutTemplate />
      </div>
    </>
  );
};

export default About;
