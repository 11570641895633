import React, { ReactNode } from 'react';

import style from './Value.module.scss';

interface IValueComponent {
  graphic: ReactNode;
  graphicAlt: string;
  title: string;
  subtitle: string;
}

const ValueComponent = ({graphic, title, subtitle}:IValueComponent) => {
  return (
    <div className={style.valueComponent}>
      {graphic}
      <p className={style.valueComponent__title}>{title}</p>
      <p className={style.valueComponent__subtitle}>{subtitle}</p>
    </div>
  )
}

export default ValueComponent;
