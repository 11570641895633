import React from 'react';

import style from './HistoryElement.module.scss';

import Time from './../../../../assets/svg/time.svg';

interface IHistoryElementComponent {
  time: string;
  text: string;
}

const HistoryElementMobileComponent = ({time, text}: IHistoryElementComponent) => {
  return (
    <div className={style.historyElementComponent}>
      <div className={style.historyElementComponent__icon}>
        <div className={style.historyElementComponent__ring}>
          <div className={style.historyElementComponent__ringTwo}>
            <Time />
          </div>
        </div>
      </div>
      <div className={style.historyElementMobileComponent__block}>
        <p className={style.historyElementComponent__time}>{time}</p>
        <p className={style.historyElementComponent__description}>{text}</p>
      </div>
    </div>
  )
}

export default HistoryElementMobileComponent;
