import React from 'react';
import style from './Number.module.scss';

interface INumberComponent {
  num: string;
  text: string;
}

const NumberComponent = ({ num, text }:INumberComponent) => {
  return (
    <div className={style.numberComponent}>
      <p className={style.numberComponent__number}>{num}</p>
      <p className={style.numberComponent__text}>{text}</p>
    </div>
  )
}

export default NumberComponent;
