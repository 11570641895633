import React from 'react';
import style from './TeamAvionaut.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

const TeamAvionautComponent = () => {
  const intl = useIntl();

  return (
    <div className={style.teamAvionaut}>
      <div className={style.teamAvionaut__left}>
        <p className={style.teamAvionaut__text}>
          {intl.formatMessage({ id: 'aboutUs__headerTwoP1' })}
        </p>
        <p className={`${style.teamAvionaut__text} ${style.teamAvionaut__space}`}>
          {intl.formatMessage({ id: 'aboutUs__headerTwoP2' })}
        </p>
      </div>
      <div className={style.teamAvionaut__middle}>
        <p className={style.teamAvionaut__text}>
          {intl.formatMessage({ id: 'aboutUs__headerTwoP3' })}
        </p>
        <p className={`${style.teamAvionaut__sign} ${style.teamAvionaut__space}`}>{intl.formatMessage({ id: 'aboutUs__headerTwoSign' })}</p>
      </div>
      <div className={style.teamAvionaut__right}>
        <StaticImage
          src={'../../../../assets/images/team-avionaut.jpg'}
          alt={'Avionaut office'}
          objectFit={'cover'}
          className={style.teamAvionaut__image}
          quality={100}
        />
      </div>
    </div>
  )
}

export default TeamAvionautComponent;
